.homepage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    position: relative;
    /*z-index: 1;*/
  }
  .menu{
    margin-top: 8vh;
  }
  .logo {
    background-color: black;
    margin-left: 3rem;
    width: 14rem;
  }
  
  .logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .menuoption{
    display: flex;
    justify-content: center;
    box-shadow: 8px 9px 15px 0 grey;
    height: max-content;
    margin-bottom: 2%;
  }
  
  .menuoption a {
    border: 3px solid transparent;
    display: block;
    text-decoration: none;
  }

  .menuoption a:hover {
    border: 3px solid #00A94E;
    text-decoration: none;
  }
  .menuoption img:active {
    border-color: #00A94E;
    border:3px solid #00A94E;
    
  }

  .menuoption img{
    width: 320px;
    height: 45vh;
    padding: 10%;
    margin-bottom: 5%;    
  }

  .menuoption h2{
    position:absolute;
    
    margin-top: 28rem;
    color:grey;
    font-weight:bolder !important;
  }
  .menuoption h2:active {
    color: #00A94E;
   
  }

  .menu{
    display:flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  
  @media only screen and (max-width: 900px) {
    .logo {
      width: 10rem;
      height: auto;
    }
  }
  
  .btn-white {
    text-transform: uppercase;
    color:white;
    background-color:#00A94E;
    margin: 1rem 0;
    margin-top: -1rem;
    transition: all 500ms ease;
    width:15rem;
    height:4rem;
    border-radius: 5px;
    box-shadow: grey;
    font-size: large;
    font-weight: bold;
  }
  
  .btn-transparent {
    text-transform: uppercase;
    color:white;
    background-color:#00A94E;
    margin: 1rem 0;
    margin-top: 26rem;
    transition: all 500ms ease;
    width:15rem;
    height:4rem;
    border-radius: 5px;
    box-shadow: grey;
    font-size: large;
    font-weight: bold;
  }

  #pdpTopContent {
    background: #272727 url(//s7d9.scene7.com/is/image/minesafetyappliances/globe-background-product-category-fire-1?scl=1&fmt=png-alpha) no-repeat;
    background-position: center;
    color: #fff;
    padding: 0 1rem;
    background-size: cover;
  }

  #pdpTopContent h2, #pdpTopContent a {
    color: #fff;
    text-decoration: none;
    text-align: left;
    text-transform: none !important;
    font-size: 2.5rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    padding: 0.83em 0;
  }
  .green-highlight {
    color: #00a943;
  }

  .container-message {
    text-align: center;
    font-size: 20px;
    padding: 0 50px;
  }

  .msa-product-item {
    position: relative;
    border: 1px solid #ebebeb;
    padding-bottom: 1.125rem;
    margin-bottom: 1.875rem;
    position: relative;
    cursor: pointer;
  }

  .head3, .head1 { 
    text-transform: none;
    font-size: 20px;
    font-family: "Open Sans Bold";
    font-family: "Titillium Web";
    font-weight: 700;
    line-height: 26px;
    padding-bottom: 0.625rem;
    margin: 0;
    display: inline-block;
    text-align: center;
    min-height: 80px;
  }

  .msa-product-item .msa-product-details {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    position: relative;
    text-align: center;
  }
  
  .configure-btn {
    width: 90%;
    margin: 10px 12px;
    padding: 0.9375rem 1.875rem;
    line-height: 1;
    color: #fff;
    background-color: #00a94e;
    min-width: 250px;
    vertical-align: middle;
    white-space: nowrap;
    border: none;
    font-size: 1.25rem;
    font-family: 'Titillium Web',sans-serif;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 0;
  }

  .configure-btn.disable,
  .configure-btn.disable:hover {
    background-color: #ddd;
  }

  .configure-btn:hover {
    background-color: #007537
  }